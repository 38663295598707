import classnames from 'classnames'
import styled from 'styled-components'
import PlusSVG from 'client/assets/svg/icon/plus_20.svg'
import FileUploader, { FileUploadChangeEvent } from 'client/components/FileUploader/FileUploader'
import { t } from 'client/i18n'
import { Label1CSS, Body1CSS } from 'client/components/TextStyles'
import { MouseEventHandler } from 'react'

const css = require('./Header.css')

const PickerHeader = styled.div`
  ${Body1CSS};
  height: 64px;
  padding: 24px 24px 0;
  color: var(--color-white);
`
const PickerTitle = styled.div`
  ${Label1CSS};
  margin: 2px 0 0;
  text-align: center;
`
const CancelLink = styled.div`
  float: left;
  color: var(--color-white);
  cursor: pointer;
`
const ApplyLink = styled.div<{ allowSubmit: boolean }>`
  float: right;
  color: ${({ allowSubmit }) => (allowSubmit ? 'var(--color-white)' : 'var(--color-grey-05)')};
  cursor: ${({ allowSubmit }) => (allowSubmit ? 'pointer' : 'default')};
`

interface IHeaderOptions {
  className?: string
}

interface ICancelProps extends IHeaderOptions {
  onClick: MouseEventHandler<HTMLDivElement>
}

export const Cancel = (props: ICancelProps) => {
  const { onClick, className } = props
  return (
    <CancelLink className={className} onClick={onClick}>
      {t('Cancel')}
    </CancelLink>
  )
}

interface ITitleProps extends IHeaderOptions {
  title: string
}

export const Title = (props: ITitleProps) => {
  const { title, className } = props
  return <PickerTitle className={className}>{title}</PickerTitle>
}

interface ISubmitProps {
  allowSubmit: boolean
  onSubmit: MouseEventHandler<HTMLDivElement>
  label: string
}

export const Submit = (props: ISubmitProps) => {
  const { allowSubmit, onSubmit, label } = props
  return (
    <ApplyLink allowSubmit={allowSubmit} onClick={allowSubmit ? onSubmit : undefined}>
      {label}
    </ApplyLink>
  )
}

interface IUploadProps extends IHeaderOptions {
  onUpload: (event: FileUploadChangeEvent) => void
  uploadFileFormats?: string[]
}

export const Upload = (props: IUploadProps) => {
  const { onUpload, className, uploadFileFormats } = props
  const classNames = classnames(css.upload, className)

  return (
    <FileUploader
      svgComponent={PlusSVG}
      name="images"
      onChange={onUpload}
      className={classNames}
      multiple={true}
      fileFormats={uploadFileFormats}
    />
  )
}

interface IMiniPickerHeaderProps {
  children: any
  options: any[]
}

const MiniPickerHeader = (props: IMiniPickerHeaderProps) => {
  const { options, children } = props

  return (
    <>
      <PickerHeader>{options.map((item) => item())}</PickerHeader>
      {children}
    </>
  )
}

export default MiniPickerHeader
