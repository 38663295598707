import * as React from 'react'
import { Field, FieldAttributes, FieldProps } from 'formik'
import _ from 'lodash'
import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import TranslatableFormField from 'client/components/TranslationForm/TranslatableFormField'
import CharacterLimitLabel from 'client/components/Formik/CharacterLimitLabel/CharacterLimitLabel'
import { FormikInputBase, ITextInputProps } from 'client/components/Formik/TextInput/TextInput'

interface IProps extends ITextInputProps {
  translatable?: boolean
  additionalLabelNode?: React.ElementType
}

const FormikTextInput = (props: IProps & FieldProps) => {
  const {
    field,
    label,
    form,
    additionalLabelNode,
    maxLength,
    description,
    translatable = true
  } = props
  const { name } = field
  const { errors, touched } = form
  const showError = _.get(errors, name) && _.get(touched, name)

  return (
    <TranslatableFormField
      label={label}
      additionalLabelNode={additionalLabelNode}
      description={description}
      hint={maxLength && <CharacterLimitLabel maxLength={maxLength} name={name} />}
      translatable={translatable}
    >
      <FormikInputBase {...props} />
      {showError && <ErrorMessage name={name} />}
    </TranslatableFormField>
  )
}

type ITranslatableTextInputFieldProps = Omit<IProps, 'field' | 'form' | 'meta'>
// Can be removed by using useField() hook directly in FormikRichTextEditor when formik is upgraded without issues
const TranslatableTextInputField = (props: FieldAttributes<ITranslatableTextInputFieldProps>) => (
  <Field {...props} component={FormikTextInput} />
)

export default TranslatableTextInputField
