import * as React from 'react'
import styled from 'styled-components'

interface IRadioButtonContainerProps {
  disabled?: boolean
}
const RadioButtonContainer = styled.div<IRadioButtonContainerProps>`
  display: flex;
  align-items: center;
  opacity: ${({ disabled = false }) => (disabled ? '.4' : '1')};
  pointer-events: ${({ disabled = false }) => (disabled ? 'none' : 'inherit')};

  &:last-child {
    margin-right: 0;
  }
`

const Input = styled.input`
  appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    border: 1px solid var(--color-blue-06);

    &:after {
      content: '';
      height: 12px;
      width: 12px;
      border-radius: 50%;
      display: block;
      background-color: var(--color-blue-06);
    }
  }
`

const Label = styled.label`
  margin-left: 10px;
  margin-bottom: 0px;
  padding-bottom: 1px;
  font-weight: var(--font-weight-regular);
`

interface IRadioButtonProps {
  name?: string
  value: any
  label: string
  checked?: boolean
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export const RadioButton = (props: IRadioButtonProps) => {
  const { value, name, label, checked, onChange, disabled = false } = props
  const id = `${name}_${value}` // create a unique id
  return (
    <RadioButtonContainer disabled={disabled}>
      <Input type="radio" name={name} value={value} id={id} checked={checked} onChange={onChange} />
      <Label htmlFor={id}>{label}</Label>
    </RadioButtonContainer>
  )
}

const RadioButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;

  div:not(:last-child) {
    margin-bottom: var(--spacing-xsmall);
  }
`
interface IRadioButtonGroupProps {
  name: string
  value: any
  onChange: (value: any) => void
  children: React.ReactElement<IRadioButtonProps>[]
  className?: any
}

export const RadioButtonGroup = ({
  name,
  value,
  onChange,
  children,
  className
}: IRadioButtonGroupProps) => {
  return (
    <RadioButtonGroupContainer className={className}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            name,
            checked: child.props.value === value,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)
          })
        }
      })}
    </RadioButtonGroupContainer>
  )
}
