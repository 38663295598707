import { setLocale as setYupLocale } from 'yup'
import _ from 'lodash'
import { MessageParams } from 'yup/lib/types'
import { TFunction } from 'i18next'
import { TKey } from 'shared/i18n/types/translationResources'

export function setupYupDefaultMessages(t: TFunction, makeLabelsUpperFirst: boolean = true) {
  function getLabel({ label, path }: MessageParams) {
    return (
      label ||
      _.chain(path)
        .split('.')
        .last()
        .thru((val) => (makeLabelsUpperFirst ? _.upperFirst(val) : val))
        .value()
    )
  }

  // Using `setLocale` allows us override built-in validation messages globally
  // This way we don't have to repeat our standard required message every time we use a `required()` validation
  // Technically this mechanism is intended for overriding for different languages, but it works well for this purpose too
  // TODO: figure out how to make this play nice with eslint rule
  /* eslint-disable docent/require-translation-keys-to-be-literals */
  setYupLocale({
    mixed: {
      required: (params) => `${t(getLabel(params) as TKey)}: ${t('This field is required.')}`,
      oneOf: (params) =>
        `${t(getLabel(params) as TKey)}: ${t('Must be one of [__values__]', {
          values: params.values
        })}`
    },
    string: {
      max: (params) => `${t(getLabel(params) as TKey)}: ${t('Max character limit exceeded.')}`,
      url: (params) =>
        `${t(getLabel(params) as TKey)}: ${t(
          'Must be a valid URL (beginning with http:// or https://).'
        )}`
    },
    number: {
      positive: (params) => `${t(getLabel(params) as TKey)}: ${t('Must be a positive number.')}`,
      integer: (params) =>
        `${t(getLabel(params) as TKey)}: ${t('This field must be a whole number.')}`
    }
  })
  /* eslint-enable docent/require-translation-keys-to-be-literals */
}
