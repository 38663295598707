import { useQuery } from '@apollo/client'
import { ApolloError, DocumentNode, OperationVariables } from '@apollo/client/core'
import GQLErrorRenderer from 'client/components/GQLErrorRenderer'
import PageContent, { PageContentView } from 'client/components/PageContent/PageContent'
import Table, { IColumnProps } from 'client/dsm/Table/Table'
import { UploadMediaType } from 'client/hooks/useMediaUpload'
import { t } from 'client/i18n'
import { UPDATED_AT_COLUMN } from 'client/screens/Catalog/grids/shared/columns'
import { SearchHeaderContainer } from 'client/screens/Catalog/grids/shared/styledComponents'
import { getFilteredData } from 'client/util/filters'
import { extractGQLData } from 'client/util/graphql'
import _ from 'lodash'
import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { Route, Routes, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { DroppableMediaFileView } from './DroppableMediaFileView'
import {
  ButtonContainer,
  FilterSearch,
  GridWrapper,
  HeaderTextContainer,
  StyledLabel,
  TableWrapper
} from './styledComponents'

interface IQueryProps {
  gqlQuery: DocumentNode
  gqlVariables?: OperationVariables
  gqlFilterFunction?: (row: any) => boolean
}

interface IDataResult<T> {
  loading: boolean
  data: T
  error?: ApolloError
}

function useData<TData = any>(props: IQueryProps): IDataResult<TData[]> {
  const { gqlQuery, gqlFilterFunction, gqlVariables: variables } = props

  const { loading, data, error } = useQuery(gqlQuery, {
    notifyOnNetworkStatusChange: true,
    variables
  })
  const rowsData = extractGQLData<any[]>(data) || []
  const filteredData = gqlFilterFunction ? _.filter(rowsData, gqlFilterFunction) : rowsData
  return { loading, data: filteredData, error }
}

interface CountLabelProps {
  dataLength: number
  searchQuery: string
}

const CountLabel = ({ searchQuery, dataLength }: CountLabelProps) => {
  if (!searchQuery) {
    return <StyledLabel>({dataLength})</StyledLabel>
  }

  const lengthLabel =
    dataLength === 0
      ? t('gridFilterNoResultsLabel', { searchQuery: searchQuery.trim() })
      : t('gridFilterResultsLabel', { count: dataLength, searchQuery: searchQuery.trim() })

  return <StyledLabel>{lengthLabel}</StyledLabel>
}

const DroppablePageContent = styled(PageContent)<{ isDragActive: boolean }>`
  ${PageContentView} {
    ${({ isDragActive }) =>
      isDragActive ? 'border: 2px dashed var(--color-blue-06)' : 'border: var(--border-light)'};
  }
`

// Even though this is meant to be a generic component, we basically have to explicitly list out
// the union of all possible ContentName strings in order to take advantage of strict TS checking
// around JS string interpolation in the i18next `t` calls
//
// Alternatively, we could use some more advanced TS features like conditional typing with `infer`:
//
//   type constraint1<T> = T extends `${infer P}s` ? P : never
//   type constraint2<T> = T extends `No ${infer P}s have been added yet.` ? P : never
//   type constraint3<T> = T extends `No ${infer P}s found.` ? P : never
//   type constraint4<T> = T extends `Add ${infer P}` ? P : never
//   type ContentName = constraint1<TKey> & constraint2<TKey> & constraint3<TKey> & constraint4<TKey>
//
// However, this seems less useful because it makes the TS errors more indirect. When adding a new
// string to this union, the programmer will see TS failures at each specific interpolation that is
// found to be missing in our translation data files. But making a similar change with the inferred
// types above would lead to a TS error just where the new contentName attr value is being passed
// to the BaseGridView component. That TS error could only be fixed by adding every missing
// interpolation, the universe of which would have to be manually gathered.
type ContentName =
  | 'Accessibility Suggestion'
  | 'Guide Start'
  | 'First Open'
  | 'Video'
  | 'Tour'
  | 'Item'
  | 'Image'
  | 'Exhibition'
  | 'Event'
  | 'Creator'
  | 'Botanical Item'
  | 'Audio'
  | 'User'
  | 'Guide'
interface IBaseGridViewProps extends IQueryProps {
  contentName: ContentName
  contextualHelp?: ReactNode
  columns: IColumnProps[]
  buttons?: ReactNode
  filterCriteria?: string[]
  formComponent?: (() => ReactElement) | { [path: string]: () => ReactElement }
  droppableFileFormats?: { [key: string]: string[] }
  uploadMediaType?: UploadMediaType
  navigatePath?: (rowData: any) => string
  placeholder?: ReactNode
  placeholderIcon?: ReactNode
}
export default function BaseGridView(props: IBaseGridViewProps) {
  const {
    gqlQuery,
    gqlVariables,
    gqlFilterFunction,
    contentName,
    contextualHelp,
    columns,
    buttons,
    filterCriteria,
    droppableFileFormats,
    uploadMediaType,
    navigatePath = (rowData) => `${rowData.id}`,
    formComponent: FormComponent,
    placeholder,
    placeholderIcon
  } = props

  const navigate = useNavigate()
  const { loading, data, error } = useData({
    gqlQuery,
    gqlFilterFunction,
    gqlVariables
  })

  const [searchQuery, setSearchQuery] = useState('')
  const onFilterCancel = useCallback(() => setSearchQuery(''), [])
  const [isBorderActive, setIsBorderActive] = useState(false)
  const onDragEnter = useCallback(() => setIsBorderActive(true), [])
  const onDragLeave = useCallback(() => setIsBorderActive(false), [])

  const actualFilterCriteria = filterCriteria || _.map(columns, 'dataKey')
  const filteredData = useMemo(
    () => getFilteredData(data, actualFilterCriteria, searchQuery),
    [data, actualFilterCriteria, searchQuery]
  )

  if (error) {
    return <GQLErrorRenderer error={error} />
  }

  const pluralContentName = `${contentName}s` as const

  /* eslint-disable docent/require-translation-keys-to-be-literals */
  const title = t(pluralContentName)
  const defaultNoContentText = t(`No ${pluralContentName} have been added yet.`)
  const defaultNoContentFoundText = t(`No ${pluralContentName} found.`)
  const addText = t(`Add ${contentName}`)
  /* eslint-enable docent/require-translation-keys-to-be-literals */

  const placeHolderComponent = placeholder ?? (
    <>
      <p>{defaultNoContentText}</p>
      <p>
        <Trans i18nKey="Click __addText__ to continue." values={{ addText }} />
      </p>
    </>
  )

  // eslint-disable-next-line docent/require-translation-keys-to-be-literals
  const tablePlaceholder = _.isEmpty(searchQuery) ? placeHolderComponent : defaultNoContentFoundText

  // If we have updatedAt column, we will default sort to latest ordering on that column.
  const defaultSortColumnDataKey = _.find(columns, { dataKey: UPDATED_AT_COLUMN.dataKey })
    ? UPDATED_AT_COLUMN.dataKey
    : undefined

  const rowClickHandler = (rowData: any) => FormComponent && navigate(navigatePath(rowData))

  const content = (
    <>
      {loading && <LoadingOverlay />}
      <GridWrapper>
        <Table
          data={filteredData}
          columns={columns}
          onRowClick={({ rowData }) => rowClickHandler(rowData)}
          placeholder={tablePlaceholder}
          placeholderIcon={placeholderIcon}
          defaultSortColumnDataKey={defaultSortColumnDataKey}
        />
      </GridWrapper>
    </>
  )
  // eslint-disable-next-line docent/require-translation-keys-to-be-literals
  const filterPlaceholder = t(`Filter ${pluralContentName}`)
  return (
    <DroppablePageContent
      isDragActive={isBorderActive}
      title={
        <HeaderTextContainer>
          {title}
          {contextualHelp}
          {!_.isEmpty(data) && (
            <CountLabel dataLength={filteredData?.length} searchQuery={searchQuery} />
          )}
        </HeaderTextContainer>
      }
      controls={
        !_.isEmpty(data) && (
          <SearchHeaderContainer>
            <FilterSearch
              value={searchQuery}
              placeholder={filterPlaceholder}
              onChange={setSearchQuery}
              onCancel={onFilterCancel}
            />
            {buttons ? <ButtonContainer>{buttons}</ButtonContainer> : null}
          </SearchHeaderContainer>
        )
      }
    >
      {uploadMediaType && !_.isEmpty(droppableFileFormats) ? (
        <DroppableMediaFileView
          isEmpty={_.isEmpty(filteredData)}
          droppableFileFormats={droppableFileFormats}
          uploadMediaType={uploadMediaType}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
        >
          {content}
        </DroppableMediaFileView>
      ) : (
        <TableWrapper isEmpty={_.isEmpty(filteredData)}>{content}</TableWrapper>
      )}
      {FormComponent &&
        (typeof FormComponent === 'function' ? (
          <Routes>
            <Route path="new/*" element={<FormComponent />} />
            <Route path=":id/*" element={<FormComponent />} />
          </Routes>
        ) : (
          <Routes>
            {_.map(FormComponent, (Component, path) => (
              <Route path={path} element={<Component />} key={path} />
            ))}
          </Routes>
        ))}
    </DroppablePageContent>
  )
}
