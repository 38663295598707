import { ElementType } from 'react'
import { Field, FieldAttributes, FieldProps } from 'formik'
import styled from 'styled-components'
import _ from 'lodash'
import RichTextEditor, { IRichTextEditorProps } from 'client/components/RichTextEditor'

import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import FieldContainer from 'client/components/Form/FieldContainer'
import { getCharacterCountForHtml } from 'shared/util/html'
import CharLimitLabel from 'client/components/Form/CharLimitLabel/CharLimitLabel'

const StyledRichTextEditor = styled(RichTextEditor)<{ isError?: boolean }>`
  &&,
  &&:hover,
  &&:focus {
    border: ${({ isError }) =>
      isError
        ? '1px solid var(--color-status-error)'
        : '1px solid var(--input-border-placeholder)'};
  }
`

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Label = styled.div`
  font-size: 14px;
  font-weight: var(--font-weight-semi-bold);
  display: flex;

  > :last-child {
    margin-right: 0;
  }

  button {
    margin-left: 4px;
  }
`

const Description = styled.div<{ hasLabel: boolean }>`
  font-size: 14px;
  font-weight: var(--font-weight-regular);
  padding-top: ${({ hasLabel }) => (hasLabel ? 4 : 0)}px;
  white-space: pre-line;
`

interface IFormikRichTextEditorProps {
  label?: string
  description?: string
  maxLength?: number
  fieldContainer?: ElementType
  placeholder?: string
  additionalLabelNode?: ElementType
  translatable?: boolean
  mode?: IRichTextEditorProps['mode']
}

const FormikRichTextEditor = (props: IFormikRichTextEditorProps & FieldProps) => {
  const {
    field,
    label,
    description,
    maxLength,
    form,
    placeholder,
    mode,
    fieldContainer: FormFieldContainer = FieldContainer,
    additionalLabelNode: AdditionalLabelNode,
    ...rest
  } = props
  const { name, value, onChange, onBlur } = field
  const { errors, touched, setFieldTouched } = form
  const isError = !!(_.get(errors, name) && _.get(touched, name))
  const hasAnyLabelNodes = label || !_.isNil(AdditionalLabelNode)
  const hasLabelContent = hasAnyLabelNodes || maxLength

  return (
    // TODO: Move the label, tooltip and possibly others, into the FormField (like other fields do)
    <FormFieldContainer {...rest}>
      {hasLabelContent && (
        <LabelContainer>
          {hasAnyLabelNodes && (
            <Label>
              {label && <span>{label}</span>}
              {!_.isNil(AdditionalLabelNode) && <AdditionalLabelNode />}
            </Label>
          )}
          {maxLength && (
            <CharLimitLabel maxLength={maxLength} currentLength={getCharacterCountForHtml(value)} />
          )}
        </LabelContainer>
      )}

      {description && <Description hasLabel={!!label}>{description}</Description>}
      <StyledRichTextEditor
        name={name}
        value={value}
        onChange={onChange}
        onBlur={() => {
          setFieldTouched(name)
          onBlur(name)
        }}
        isError={isError}
        mode={mode}
      />
      {isError && <ErrorMessage name={name} />}
    </FormFieldContainer>
  )
}

// Can be removed by using useField() hook directly in FormikRichTextEditor when formik is upgraded without issues
const RichTextEditorField = (props: FieldAttributes<IFormikRichTextEditorProps>) => (
  <Field component={FormikRichTextEditor} {...props} />
)

export default RichTextEditorField
