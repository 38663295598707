import { useMemo, ReactNode } from 'react'
// @ts-expect-error No types available atm
import Select from 'react-select-virtualized'
import styled from 'styled-components'
import { StylesConfig } from 'react-select'

interface IOption {
  value: any
  label: ReactNode
}

interface IProps {
  name: string
  value?: IOption
  options?: IOption[]
  onChange: (selection: IOption) => void
  placeholder?: string
  optionHeight?: number
  height?: number
}

// To adjust the height of react-select component, we need to pass the height prop into
// multiple inner element, hence making height an optional param here for easier usage.
// Height adjusment is inspired by: https://stackoverflow.com/questions/54218351/changing-height-of-react-select-component
const getCustomStyles = (height?: number): StylesConfig<any> => {
  return {
    container: (base) => ({
      ...base,
      borderWidth: 0,
      ...(height ? { height } : {}),
      fontSize: 14
    }),
    control: (base) => ({
      ...base,
      boxShadow: 'none',
      borderWidth: 0,
      borderRadius: 0,
      backgroundColor: 'var(--color-white)',
      backgroundImage: 'linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)',
      backgroundPosition: 'bottom',
      backgroundSize: '3px 1px',
      backgroundRepeat: 'repeat-x',
      ...(height ? { height } : {})
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
      ...(height ? { height } : {})
    }),
    clearIndicator: () => ({ display: 'none' }),
    indicatorSeparator: () => ({}),
    indicatorsContainer: (base) => ({
      ...base,
      ...(height ? { height } : {})
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
      color: 'var(--color-accent-01)',
      '&:hover': {
        color: 'var(--color-accent-02)'
      }
    }),
    valueContainer: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
      ...(height ? { height } : {})
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      margin: 0
    }),
    menuList: (base) => ({
      ...base,
      margin: 0,
      padding: 0
    })
  }
}

const VirtualSelect = styled(Select)`
  background: var(--color-white);
  .fast-option {
    &:hover {
      background: var(--color-grey-02);
    }
    &:hover:active {
      background: var(--color-brand);
      color: var(--color-white);
    }
  }
  .fast-option-focused {
    background: var(--color-white);
  }
`

export default (props: IProps) => {
  const customStyles = useMemo(() => getCustomStyles(props.height), [props.height])
  return <VirtualSelect styles={customStyles} {...props} />
}
