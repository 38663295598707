import { ReorderableVideoRow } from 'client/components/ContentRow/ReorderableRow'
import { PickerVideoRow } from 'client/components/ContentRow/PickerRow'
import gql from 'graphql-tag'
import DataAwareReorderableList from 'client/components/Formik/ReorderableList/DataAwareReorderableList'
import { SUPPORTED_VIDEO_MIME_TYPES } from 'shared/constants/video'
import { UploadMediaType } from 'client/hooks/useMediaUpload'

const VIDEOS_MINIPICKER_QUERY = gql`
  query videosForMinipicker($museumId: Int!) {
    museum(id: $museumId) {
      id
      videos {
        id
        url
        sourceUrl
        title
        size
        transcript
        duration
        isProcessing
        processingError
        isMarketingUseAllowed
        translations {
          locale {
            id
            englishName
            code
          }
          videoSubtitles {
            id
          }
        }
        posterImageUrl
        locales {
          id
          code
          englishName
        }
      }
    }
  }
`

interface IVideosListProps {
  name: string
}

export default function VideosList({ name }: IVideosListProps) {
  return (
    <DataAwareReorderableList
      name={name}
      rowComponent={ReorderableVideoRow}
      pickerRowComponent={PickerVideoRow}
      filterCriteria={['title', 'fileName']}
      contentName="Video"
      gqlQuery={VIDEOS_MINIPICKER_QUERY}
      uploadMedia={{
        uploadFileFormats: SUPPORTED_VIDEO_MIME_TYPES,
        type: UploadMediaType.VIDEO
      }}
    />
  )
}
