import SelectBox, {
  ISelectBoxOptions,
  ISelectBoxProps
} from 'client/components/Form/SelectBox/SelectBox'
import { LightThemeStyles } from 'client/components/Form/SelectBox/themes'
import _ from 'lodash'
import { useMemo } from 'react'
import { StylesConfig } from 'react-select'
import { GQLBuilding } from 'shared/graphql/types/graphql'
import styled from 'styled-components'

const customSelectStyles: StylesConfig<any> = {
  ...LightThemeStyles,
  container: (base, state) => ({
    ...LightThemeStyles.container(base, state),
    borderBottom: '1px dotted var(--color-grey-02)'
  }),
  control: (base, state) => ({
    ...LightThemeStyles.control(base, state),
    borderWidth: 0
  }),
  menu: (base, state) => ({
    ...LightThemeStyles.menu(base, state),
    borderTop: undefined
  })
}

const LocationSelect = styled(SelectBox)`
  width: 240px;
  margin-right: 16px;
`

function buildingsToOptions(buildings: GQLBuilding[]): ISelectBoxOptions[] {
  return _.map(buildings, (building) => {
    return {
      value: building.id,
      label: building.name,
      options: _.map(building.mapLocations, (location) => ({
        value: location.id,
        label: location.rawName
      }))
    }
  })
}

interface IContentMapLocationSelect {
  onChange: ISelectBoxProps['onChange']
  placeholder?: string
  selectedMapLocationId: number | undefined
  buildings: GQLBuilding[]
}

export default function ContentMapLocationSelect(props: IContentMapLocationSelect) {
  const { onChange, selectedMapLocationId, placeholder, buildings } = props

  const mapLocationOptions = useMemo(() => buildingsToOptions(buildings), [buildings])
  const allOptions = _.flatMap(mapLocationOptions, 'options')
  const selectedMapLocationOption = _.find(allOptions, { value: selectedMapLocationId })

  return (
    <LocationSelect
      options={mapLocationOptions}
      onChange={onChange}
      isSearchable={true}
      value={selectedMapLocationOption}
      placeholder={placeholder}
      styles={customSelectStyles}
    />
  )
}
