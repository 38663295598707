import { useState } from 'react'
import * as React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import VerticalEllipsisSVG from 'client/assets/svg/icon/ellipsis_20_vertical.svg'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import Tippy, { TippyProps } from '@tippyjs/react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Instance } from 'tippy.js'
import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'
import { ThemeType } from 'client/types'
import { t } from 'client/i18n'

export interface IMenuOption {
  label: string
  content?: React.ReactNode
  onClick?: (item: IMenuOption) => void

  disabled?: boolean
  disabledTooltip?: React.ReactNode
  disabledTooltipPlacement?: TippyProps['placement']
}

interface IMenuPopoutProps {
  options: Array<IMenuOption>
  placement?: TippyProps['placement']
  children?: React.ReactNode
  className?: string
  theme?: ThemeType
  tooltipPlacement?: TippyProps['placement']
  testId?: string
  appendTo?: TippyProps['appendTo']
}

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const MenuOption = styled.li<{ clickable?: boolean }>`
  && {
    padding-left: 12px;
    padding-right: 12px;
    cursor: ${({ clickable = true }) => (clickable ? 'pointer' : 'default')};
    :hover {
      background-color: ${({ clickable }) =>
        clickable ? 'var(--color-grey-01)' : 'var(--color-white)'};
    }
  }
`
const DividerMenuOption = styled(MenuOption)`
  && {
    min-height: inherit;
    padding: 0px;
    margin: 8px 12px;
    border-bottom: 1px solid var(--color-grey-02);
    cursor: default;
    &:hover {
      background-color: inherit;
    }
  }
`

const IconButtonContainer = styled.div`
  height: 20px;
`

interface IDisabledWrapperProps {
  isDisabled: boolean | undefined
}
const DisabledWrapper = styled.div<IDisabledWrapperProps>`
  color: ${({ isDisabled }) => (isDisabled ? 'var(--color-grey-04)' : null)};
`

interface IContentWrapperProps {
  disabled: IMenuOption['disabled']
  disabledTooltip: IMenuOption['disabledTooltip']
  disabledTooltipPlacement: IMenuOption['disabledTooltipPlacement']
  children: React.ReactNode
}
function ContentWrapper({
  children,
  disabled,
  disabledTooltip,
  disabledTooltipPlacement
}: IContentWrapperProps) {
  const wrappedContent = <DisabledWrapper isDisabled={disabled}>{children}</DisabledWrapper>

  if (!disabledTooltip || !disabled) {
    return wrappedContent
  }

  return (
    <Tooltip content={disabledTooltip} placement={disabledTooltipPlacement}>
      {wrappedContent}
    </Tooltip>
  )
}

export const DIVIDER_MENU_OPTION = { label: 'MenuPopout Divider' }

const createPopoutContent = (options: IMenuOption[], closeMenu: () => void) => {
  const listOptions = _.map(options, (option, index) => {
    const {
      label,
      onClick = _.noop,
      disabled,
      disabledTooltip,
      disabledTooltipPlacement = 'right'
    } = option

    const content = option.content ?? label

    const handleClick: React.MouseEventHandler = (e) => {
      if (disabled) {
        return
      }
      e.stopPropagation()
      closeMenu()
      onClick(option)
    }

    if (label === DIVIDER_MENU_OPTION.label) {
      return <DividerMenuOption key={index} />
    }

    return (
      <MenuOption key={label} data-testid={label} onClick={handleClick} clickable={!disabled}>
        <ContentWrapper
          disabled={disabled}
          disabledTooltip={disabledTooltip}
          disabledTooltipPlacement={disabledTooltipPlacement}
        >
          {content}
        </ContentWrapper>
      </MenuOption>
    )
  })
  return <ul>{listOptions}</ul>
}

function MenuPopout(props: IMenuPopoutProps) {
  const {
    options = [],
    placement = 'bottom-start',
    className,
    theme = ThemeType.DARK,
    tooltipPlacement = 'top',
    testId,
    appendTo
  } = props

  const [instance, setInstance] = useState<Instance>()
  const [isMenuPopoutShowing, setIsMenuPopoutShowing] = useState(false)

  const closeMenu = () => {
    instance!.hide()
  }

  // Do not render if there are no options to show
  if (_.isEmpty(options)) {
    return null
  }

  const content = createPopoutContent(options, closeMenu)

  const verticalEllipsisButton = (
    <IconButtonContainer>
      <IconOnlyButton
        theme={theme}
        svgComponent={VerticalEllipsisSVG}
        testId={testId}
        onClick={(e) => e.stopPropagation()}
      />
    </IconButtonContainer>
  )

  const defaultChildren = isMenuPopoutShowing ? (
    verticalEllipsisButton
  ) : (
    <Tooltip themeType={theme} content={t('More actions')} placement={tooltipPlacement}>
      {verticalEllipsisButton}
    </Tooltip>
  )

  return (
    <Tippy
      content={content}
      placement={placement}
      trigger="click"
      maxWidth="100%"
      interactive={true}
      offset={[0, 1]}
      animation={false}
      arrow={false}
      className={className}
      appendTo={appendTo}
      onShow={() => setIsMenuPopoutShowing(true)}
      onHide={() => setIsMenuPopoutShowing(false)}
      // Note: not setting `hideOnClick={true}` here as it causes problems with map pin tooltips
      // We explicitly close the tooltip via `instance.hide()` anyway
      // hideOnClick={true}
      onCreate={(popperInstance) => setInstance(popperInstance)}
    >
      <FlexCenter>{props.children ?? defaultChildren}</FlexCenter>
    </Tippy>
  )
}

export default styled(MenuPopout)`
  padding: 0;
  display: flex;
  line-height: 20px;

  && {
    z-index: 1300;
    border-radius: 3px;
    color: var(--color-black);
    background-color: var(--color-white);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    .tippy-content {
      padding: 0;
    }
    .tippy-arrow::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      z-index: -1;
      left: 50%;
      box-sizing: border-box;
      transform-origin: 0 0;
      box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.15);
    }

    &[data-placement^='top'] .tippy-arrow {
      border-top-color: var(--color-white);
      &:before {
        transform: rotate(-45deg);
        bottom: -3px;
        margin-left: -7px;
        box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);
      }
    }
    &[data-placement^='bottom'] .tippy-arrow {
      border-bottom-color: var(--color-white);
      &:before {
        transform: rotate(135deg);
        bottom: -17px;
        margin-left: 7px;
        box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.1);
      }
    }
    &[data-placement^='left'] .tippy-arrow {
      border-left-color: var(--color-white);
      &:before {
        transform: rotate(-135deg);
        bottom: -17px;
        margin-left: -7px;
      }
    }
    &[data-placement^='right'] .tippy-arrow {
      border-right-color: var(--color-white);
      &:before {
        transform: rotate(45deg);
        bottom: -3px;
        margin-left: 7px;
      }
    }
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 8px 0;
    min-width: 142px;

    li {
      cursor: pointer;
      font-size: 14px;
      position: relative;
      text-align: left;
      margin: 1px 0;
      min-height: 32px;
      padding: 6px 16px;

      & a {
        display: block;
        position: relative;
        width: inherit;
        height: 100%;
        padding: 6px 16px;
        margin: -6px -16px;
        top: 0;
        left: 0;
        color: var(--color-black);
        text-decoration: none;
        &:visited,
        &:hover,
        &:focus,
        &:active {
          color: var(--color-black);
        }
        &:hover {
          text-decoration: none;
        }
      }

      &::before {
        display: none;
        position: absolute;
        left: 16px;
        right: 16px;
        top: -1px;
        border-top: 1px solid var(--color-grey-02);
        content: '';
      }
      &:first-of-type::before {
        display: none;
      }

      &:hover {
        background-color: var(--color-grey-01);
      }
    }
  }
`
