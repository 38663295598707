import { getItemDescription, isItemContent } from 'shared/util/items'
import _ from 'lodash'
import {
  GQLAudio,
  GQLCreator,
  GQLExhibit,
  GQLFeature,
  GQLImage,
  GQLItem,
  GQLMuseum,
  GQLMuseumLocation,
  GQLVideo
} from 'shared/graphql/types/graphql'
import { ReactNode } from 'react'
import traverse from 'traverse'
import { t } from 'client/i18n'
import { getExhibitDate } from 'client/util/exhibits'
import { ExhibitionType } from 'shared/constants/exhibits'
import { ItemType } from 'shared/constants/item'
import { AssetType } from 'shared/constants/asset'

const CONTENT_TYPE_TO_URL_PATH_MAP = {
  [ExhibitionType.EXHIBITION]: 'exhibits',
  [ExhibitionType.EVENT]: 'events',
  [ExhibitionType.TOUR]: 'tours',
  [ItemType.ITEM]: 'items',
  [ItemType.BOTANICAL]: 'botanicalItems',
  [AssetType.AUDIO]: 'audios',
  [AssetType.VIDEO]: 'videos',
  [AssetType.IMAGE]: 'images'
}

// StyledComponents should not be used in this file. Rather, we need to set an inline style.
// This is because the StyledComponents won't get recognized in the word files where the Guide Export content is copied to.
// Inline styles will do the trick.
function IndentContainer({ children }: { children: ReactNode }) {
  return <div style={{ marginLeft: 10 }}>{children}</div>
}

function StyledLabelContainer({ children }: { children: ReactNode }) {
  return <div style={{ fontSize: 14, fontWeight: 600, marginBottom: 8 }}>{children}</div>
}

function GoogleTranslateLabelContainer({ children }: { children: ReactNode }) {
  return (
    <span
      style={{
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 8,
        color: '#525252',
        backgroundColor: '#dbdbdb'
      }}
    >
      {children}
    </span>
  )
}

function HTMLParagraph({ children }: { children?: string | null }) {
  // eslint-disable-next-line react/no-danger
  return <p dangerouslySetInnerHTML={{ __html: children ?? '' }} />
}

interface IContentLinkProps {
  guideId: number
  versionType: string
  contentType: keyof typeof CONTENT_TYPE_TO_URL_PATH_MAP
  id: number
}

function ContentLink({ guideId, versionType, contentType, id }: IContentLinkProps) {
  const url = `${window.location.protocol}//${window.location.host}/guide/${guideId}/version/${versionType}/${CONTENT_TYPE_TO_URL_PATH_MAP[contentType]}/${id}`

  return (
    <p>
      <a href={url} target="_blank" rel="noreferrer">
        {url}
      </a>
    </p>
  )
}
interface ITitleProps {
  title: string
  isGoogleTranslate?: boolean | null
}

const Title = ({ title, isGoogleTranslate = false }: ITitleProps) => (
  <h3>
    {title}
    {isGoogleTranslate && (
      <>
        <br />
        <GoogleTranslateLabelContainer>
          {t('Provided by Google Translate')}
        </GoogleTranslateLabelContainer>
      </>
    )}
  </h3>
)

interface IRendererProps<T> {
  content: T
}

interface IAudioRendererProps extends IRendererProps<GQLAudio> {
  guideId: number
  guideVersionType: string
}

function AudioRenderer({ content, guideId, guideVersionType }: IAudioRendererProps) {
  const { title, description, transcript, id, isGoogleTranslate } = content

  return (
    <div>
      <Title isGoogleTranslate={isGoogleTranslate} title={t('Audio: __title__', { title })} />
      <ContentLink
        guideId={guideId}
        versionType={guideVersionType}
        contentType={AssetType.AUDIO}
        id={id}
      />

      {description && (
        <>
          <StyledLabelContainer>{t('Description:')}</StyledLabelContainer>
          <HTMLParagraph>{description}</HTMLParagraph>
        </>
      )}
      {transcript && (
        <>
          <StyledLabelContainer>{t('Transcript:')}</StyledLabelContainer>
          <HTMLParagraph>{transcript}</HTMLParagraph>
        </>
      )}
    </div>
  )
}

interface IVideoRendererProps extends IRendererProps<GQLVideo> {
  guideId: number
  guideVersionType: string
}

function VideoRenderer({ content, guideId, guideVersionType }: IVideoRendererProps) {
  const { title, credits, transcript, id, isGoogleTranslate } = content

  return (
    <div>
      <Title isGoogleTranslate={isGoogleTranslate} title={t('Video: __title__', { title })} />
      <ContentLink
        guideId={guideId}
        versionType={guideVersionType}
        contentType={AssetType.VIDEO}
        id={id}
      />
      {credits && (
        <>
          <StyledLabelContainer>{t('Credits:')}</StyledLabelContainer>
          <HTMLParagraph>{credits}</HTMLParagraph>
        </>
      )}
      {transcript && (
        <>
          <StyledLabelContainer>{t('Transcript:')}</StyledLabelContainer>
          <HTMLParagraph>{transcript}</HTMLParagraph>
        </>
      )}
    </div>
  )
}

interface IImageRendererProps extends IRendererProps<GQLImage> {
  guideId: number
  guideVersionType: string
}

function ImageRenderer({ content, guideId, guideVersionType }: IImageRendererProps) {
  const { fileName, altText, caption, id, isGoogleTranslate } = content

  return (
    <div>
      <Title isGoogleTranslate={isGoogleTranslate} title={t('Image: __fileName__', { fileName })} />
      <ContentLink
        guideId={guideId}
        versionType={guideVersionType}
        contentType={AssetType.IMAGE}
        id={id}
      />
      {altText && (
        <>
          <StyledLabelContainer>{t('Alternative Text (Alt Text):')}</StyledLabelContainer>
          <HTMLParagraph>{altText}</HTMLParagraph>
        </>
      )}
      {caption && (
        <>
          <StyledLabelContainer>{t('Caption:')}</StyledLabelContainer>
          <HTMLParagraph>{caption}</HTMLParagraph>
        </>
      )}
    </div>
  )
}

function CreatorRenderer({ content }: IRendererProps<GQLCreator>) {
  const { lifedates, name, isGoogleTranslate } = content
  return (
    <div>
      <Title isGoogleTranslate={isGoogleTranslate} title={t('Creator: __name__', { name })} />
      {lifedates && (
        <>
          <StyledLabelContainer>{t('Life Dates:')}</StyledLabelContainer>
          <HTMLParagraph>{lifedates}</HTMLParagraph>
        </>
      )}
    </div>
  )
}

interface IItemRendererProps extends IRendererProps<GQLItem> {
  guideId: number
  guideVersionType: string
}

function ItemRenderer({ content, guideId, guideVersionType }: IItemRendererProps) {
  const { header } = getItemDescription(content, true)

  const {
    lookupNumber,
    medium,
    information,
    dimensions,
    credit,
    accessionNumber,
    attributes,
    bloomSeason,
    family,
    origin,
    flowers,
    leaves,
    range,
    images,
    audios,
    videos,
    type,
    id,
    creators,
    commonName,
    scientificName,
    date,
    isGoogleTranslate
  } = content

  return (
    <div>
      <Title isGoogleTranslate={isGoogleTranslate} title={t('Item: __header__', { header })} />
      <ContentLink
        guideId={guideId}
        versionType={guideVersionType}
        contentType={type as ItemType}
        id={id}
      />

      {commonName && (
        <>
          <StyledLabelContainer>{t('Common Name:')}</StyledLabelContainer>
          <HTMLParagraph>{commonName}</HTMLParagraph>
        </>
      )}

      {scientificName && (
        <>
          <StyledLabelContainer>{t('Scientific Name:')}</StyledLabelContainer>
          <HTMLParagraph>{scientificName}</HTMLParagraph>
        </>
      )}

      {lookupNumber && (
        <>
          <StyledLabelContainer>{t('Lookup Number:')}</StyledLabelContainer>
          <HTMLParagraph>{t('#__lookupNumber__', { lookupNumber })}</HTMLParagraph>
        </>
      )}

      {date && (
        <>
          <StyledLabelContainer>{t('Creation Date/Period/Era:')}</StyledLabelContainer>
          <HTMLParagraph>{date}</HTMLParagraph>
        </>
      )}

      {medium && (
        <>
          <StyledLabelContainer>{t('Materials/Medium:')}</StyledLabelContainer>
          <HTMLParagraph>{medium}</HTMLParagraph>
        </>
      )}

      {information && (
        <>
          <StyledLabelContainer>{t('Description:')}</StyledLabelContainer>
          <HTMLParagraph>{information}</HTMLParagraph>
        </>
      )}

      {bloomSeason && (
        <>
          <StyledLabelContainer>{t('Bloom Season:')}</StyledLabelContainer>
          <HTMLParagraph>{bloomSeason}</HTMLParagraph>
        </>
      )}
      {family && (
        <>
          <StyledLabelContainer>{t('Family:')}</StyledLabelContainer>
          <HTMLParagraph>{family}</HTMLParagraph>
        </>
      )}
      {origin && (
        <>
          <StyledLabelContainer>{t('Origin:')}</StyledLabelContainer>
          <HTMLParagraph>{origin}</HTMLParagraph>
        </>
      )}
      {range && (
        <>
          <StyledLabelContainer>{t('Range/Geographic Region:')}</StyledLabelContainer>
          <HTMLParagraph>{range}</HTMLParagraph>
        </>
      )}
      {flowers && (
        <>
          <StyledLabelContainer>{t('Flowers:')}</StyledLabelContainer>
          <HTMLParagraph>{flowers}</HTMLParagraph>
        </>
      )}
      {leaves && (
        <>
          <StyledLabelContainer>{t('Leaves:')}</StyledLabelContainer>
          <HTMLParagraph>{leaves}</HTMLParagraph>
        </>
      )}
      {attributes && (
        <>
          <StyledLabelContainer>{t('Attributes:')}</StyledLabelContainer>
          <HTMLParagraph>{attributes}</HTMLParagraph>
        </>
      )}
      {dimensions && (
        <>
          <StyledLabelContainer>{t('Dimensions:')}</StyledLabelContainer>
          <HTMLParagraph>{dimensions}</HTMLParagraph>
        </>
      )}
      {credit && (
        <>
          <StyledLabelContainer>{t('Credit:')}</StyledLabelContainer>
          <HTMLParagraph>{credit}</HTMLParagraph>
        </>
      )}
      {accessionNumber && (
        <>
          <StyledLabelContainer>{t('Accession Number:')}</StyledLabelContainer>
          <HTMLParagraph>{accessionNumber}</HTMLParagraph>
        </>
      )}

      <IndentContainer>
        {creators.map((creator) => (
          <CreatorRenderer key={creator.id} content={creator} />
        ))}
      </IndentContainer>
      <IndentContainer>
        {images.map((image) => (
          <ImageRenderer
            key={image.uuid}
            content={image}
            guideId={guideId}
            guideVersionType={guideVersionType}
          />
        ))}
      </IndentContainer>
      <IndentContainer>
        {audios.map((audio) => (
          <AudioRenderer
            key={audio.uuid}
            content={audio}
            guideId={guideId}
            guideVersionType={guideVersionType}
          />
        ))}
      </IndentContainer>
      <IndentContainer>
        {videos.map((video) => (
          <VideoRenderer
            key={video.uuid}
            content={video}
            guideId={guideId}
            guideVersionType={guideVersionType}
          />
        ))}
      </IndentContainer>
    </div>
  )
}

interface IExhibitRendererProps extends IRendererProps<GQLExhibit> {
  guideId: number
  guideVersionType: string
}

function ExhibitRenderer({ content, guideId, guideVersionType }: IExhibitRendererProps) {
  const {
    title,
    information,
    lookupNumber,
    audios,
    videos,
    images,
    items,
    type,
    id,
    isGoogleTranslate
  } = content

  // TODO exhibitDates need to be translated here (we also have a TODO directly in getExhibitDate)
  const exhibitDates = getExhibitDate(content)

  return (
    <>
      <Title isGoogleTranslate={isGoogleTranslate} title={t('Exhibition: __title__', { title })} />
      <ContentLink
        guideId={guideId}
        versionType={guideVersionType}
        contentType={type as ExhibitionType}
        id={id}
      />

      {information && (
        <>
          <StyledLabelContainer>{t('Description:')}</StyledLabelContainer>
          <HTMLParagraph>{information}</HTMLParagraph>
        </>
      )}

      {lookupNumber && (
        <>
          <StyledLabelContainer>{t('Lookup Number:')}</StyledLabelContainer>
          <HTMLParagraph>{t('#__lookupNumber__', { lookupNumber })}</HTMLParagraph>
        </>
      )}

      {exhibitDates && (
        <>
          <StyledLabelContainer>{t('Display Period:')}</StyledLabelContainer>
          <HTMLParagraph>{exhibitDates}</HTMLParagraph>
        </>
      )}

      <IndentContainer>
        {images.map((image) => (
          <ImageRenderer
            key={image.uuid}
            content={image}
            guideId={guideId}
            guideVersionType={guideVersionType}
          />
        ))}
      </IndentContainer>
      <IndentContainer>
        {audios.map((audio) => (
          <AudioRenderer
            key={audio.uuid}
            content={audio}
            guideId={guideId}
            guideVersionType={guideVersionType}
          />
        ))}
      </IndentContainer>
      <IndentContainer>
        {videos.map((video) => (
          <VideoRenderer
            key={video.uuid}
            content={video}
            guideId={guideId}
            guideVersionType={guideVersionType}
          />
        ))}
      </IndentContainer>

      <IndentContainer>
        {items.map((item) => (
          <ItemRenderer
            key={item.uuid}
            content={item}
            guideId={guideId}
            guideVersionType={guideVersionType}
          />
        ))}
      </IndentContainer>
    </>
  )
}

interface IFeatureRendererProps extends IRendererProps<GQLFeature> {
  guideId: number
  guideVersionType: string
}

function FeatureRenderer({ content, guideId, guideVersionType }: IFeatureRendererProps) {
  const { title, featuredItems, isGoogleTranslate } = content

  const itemsAndExhibits = _(featuredItems)
    .map((featuredItem) => featuredItem.item || featuredItem.exhibit)
    .compact()
    .value()
  return (
    <div>
      {title && (
        <Title isGoogleTranslate={isGoogleTranslate} title={t('Section: __title__', { title })} />
      )}
      {itemsAndExhibits.map((itemOrExhibit) => {
        // eslint-disable-next-line no-underscore-dangle
        return isItemContent(itemOrExhibit) ? (
          <ItemRenderer
            key={itemOrExhibit.uuid}
            content={itemOrExhibit}
            guideId={guideId}
            guideVersionType={guideVersionType}
          />
        ) : (
          <ExhibitRenderer
            key={itemOrExhibit.uuid}
            content={itemOrExhibit}
            guideId={guideId}
            guideVersionType={guideVersionType}
          />
        )
      })}
    </div>
  )
}

function LocationRenderer({ content }: IRendererProps<GQLMuseumLocation>) {
  const { name, fullAddress } = content
  return (
    <div>
      {name && (
        <>
          <StyledLabelContainer>{t('Name:')}</StyledLabelContainer>
          <HTMLParagraph>{name}</HTMLParagraph>
        </>
      )}
      <StyledLabelContainer>{t('Address:')}</StyledLabelContainer>
      <HTMLParagraph>{fullAddress}</HTMLParagraph>
    </div>
  )
}

// Only exported for testing
export function removeDuplicatesFromMuseum(content: GQLMuseum): GQLMuseum {
  const uuids: Record<string, boolean> = {}
  // eslint-disable-next-line array-callback-return
  const dedupedContent = traverse(content).map(function dedupe(thing) {
    // If this is a leaf node (primitive strings, booleans, numbers, etc) ignore it
    if (this.isLeaf || !_.has(thing, 'uuid')) {
      return
    }

    const { uuid } = thing
    if (uuids[uuid]) {
      // According to the API docs for traverse, `this.remove()` would probably be a better choice
      // However for some reason this causes the traversal of the array to immediately stop.
      // Passing stopHere=false/true doesn't have any affect
      // So instead, use `this.delete`, which replaces this element with a null
      // Then in a separate traversal we'll `_.compact()` all the arrays in the data
      this.delete()
    } else {
      uuids[uuid] = true
    }
  })

  // eslint-disable-next-line array-callback-return
  const dedupedContentWithNullsRemoved = traverse(dedupedContent).map(function compact(thing) {
    if (_.isArray(thing)) {
      this.update(_.compact(thing))
    }
  })

  return dedupedContentWithNullsRemoved
}
export function GuideExportRenderer({ content: museum }: IRendererProps<GQLMuseum>) {
  const {
    locations,
    features,
    exhibits,
    items,
    description,
    displayName,
    versionType,
    guide,
    isGoogleTranslate
  } = removeDuplicatesFromMuseum(museum)

  return (
    <>
      <h2>{t('guideExport.generatedOn.label', { date: new Date().toISOString() })}</h2>
      <h1>{t('Guide: __displayName__', { displayName })}</h1>

      {description && (
        <>
          <StyledLabelContainer>{t('Description:')}</StyledLabelContainer>
          <HTMLParagraph>{description}</HTMLParagraph>
          {isGoogleTranslate && (
            <>
              <br />
              <GoogleTranslateLabelContainer>
                {t('Provided by Google Translate')}
              </GoogleTranslateLabelContainer>
            </>
          )}
        </>
      )}

      {!_.isEmpty(locations) && (
        <>
          <StyledLabelContainer>{t('Locations & Hours')}</StyledLabelContainer>
          {locations.map((location) => (
            <LocationRenderer key={location.uuid} content={location} />
          ))}
        </>
      )}

      <h1>{t('Features:')}</h1>
      {features.map((feature) => (
        <FeatureRenderer
          key={feature.uuid}
          content={feature}
          guideId={guide.id}
          guideVersionType={versionType}
        />
      ))}

      <hr />

      <h1>{t('Exhibitions:')}</h1>
      {exhibits.map((exhibit) => (
        <ExhibitRenderer
          key={exhibit.uuid}
          content={exhibit}
          guideId={guide.id}
          guideVersionType={versionType}
        />
      ))}

      <hr />

      <h1>{t('Items:')}</h1>
      {items.map((item) => (
        <ItemRenderer
          key={item.uuid}
          content={item}
          guideId={guide.id}
          guideVersionType={versionType}
        />
      ))}
    </>
  )
}
