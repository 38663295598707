import _ from 'lodash'
import { ErrorMessage, ErrorMessageProps } from 'formik'
import styled from 'styled-components'
import { Body2CSS } from 'client/components/TextStyles'

const StyledErrorMessage = styled.div`
  ${Body2CSS};
  margin-top: 4px;
  color: var(--color-status-error);
`

/**
 * This component is used for inline errors.
 * It handles converting 'Field: The error message.' to 'The error message.' if necessary,
 * or it will simply return the error supplied.
 * The instance where it is not a string is the UserForm returning HTML.
 */
export default (props: ErrorMessageProps) => (
  <ErrorMessage {...props}>
    {(error: any) => (
      <StyledErrorMessage>
        {_.isString(error) ? _(error).split(': ').last() : error}
      </StyledErrorMessage>
    )}
  </ErrorMessage>
)
