/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, UIEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import * as yup from 'yup'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ApolloError, DocumentNode, useMutation } from '@apollo/client'
import { GraphQLInputObjectType } from 'graphql'
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik'
import { GQLLocale } from 'shared/graphql/types/graphql'
import PopupWidget from 'client/components/PopupWidget/PopupWidget'
import FormHeader from 'client/components/Form/FormHeader'
import ErrorBanner from 'client/components/TranslationForm/TranslationFormErrorBanner'
import { useTranslationFormQuery } from 'client/components/TranslationForm/useTranslationFormQuery'
import {
  FormHeaderDialogs,
  FormHeaderDialogType
} from 'client/components/TranslationForm/FormHeaderDialogs'
import TranslationsDropdown from 'client/components/TranslationForm/TranslationsDropdown'
import {
  DialogOptions,
  getDirtyLocaleCodes,
  isLocaleCode,
  mapValuesToMutationInput,
  useDialog,
  useFieldsFromMutationInput,
  useFieldsFromQuery,
  useLocales
} from 'client/components/TranslationForm/util'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import GQLErrorRenderer from 'client/components/GQLErrorRenderer'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { useDispatch } from 'react-redux'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import FormFieldSection from 'client/components/TranslationForm/TranslatableFormFieldSection'
import Banner, { BannerItem } from 'client/dsm/Banner/Banner'
import { t } from 'client/i18n'
import resolveUnknownError from 'shared/util/resolveUnknownError'
import { ContentName, FormikTranslationError } from 'client/components/TranslationForm/types'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'

function schemaValidator(
  contentSchema: TranslationFormProps['validationSchema'],
  nontranslatableFields: string[],
  defaultLocale: GQLLocale
) {
  const validator = async (
    values: any[]
  ): Promise<FormikErrors<FormikTranslationError> | undefined> => {
    if (!contentSchema) {
      return
    }
    try {
      // remove all validations associated with nontranslatable fields
      const translatableSchema = contentSchema.omit(nontranslatableFields)

      // build a new schema discerning between nontranslatable and translatable fields within Formik values
      const schema = yup.object().shape(
        _.reduce(
          values,
          (acc: Record<string, TranslationFormProps['validationSchema']>, value, key: string) => {
            // TODO: It would be good to stop mixing these locales with nontranslatable values
            /*
                  For example, currently the shape is like
  
                  {
                    'en-US': {
                      someTranslatableField: 'bar'
                    },
                    'es-ES': {
                      someTranslatableField: 'baz'
                    },
  
                    'someNonTranslatableField' : 'value',
                    'someOtherNonTranslatableField' : 'another value',
                  }
  
  
                  It'd be better to get this into a shape that's more like
  
                  {
                    "translations": [
                      {
                        "locale": "en-US",
                        "someTranslatableField": "bar"
                      },
                      {
                        "locale": "es-ES",
                        "someTranslatableField": "baz"
                      }
                    ],
                    "someNonTranslatableField" : "value",
                    "someOtherNonTranslatableField" : "another value"
                  }
  
  
                  Which matches the shape we get from the GQL fetch.
                  That way there's no need for this `isLocaleCode` checking, and there's also less transforming between
                  GQL shape -> formik shape -> GQL input shape
  
                  See https://pm.bloomberg.com/jira/browse/DOCNT-7234
               */
            if (isLocaleCode(key)) {
              acc[key] = translatableSchema // { ['en-US']: { ...validationsForTranslatableFields } }
            } else {
              // 'key' is a nontranslatable field
              // check if the supplied schema has a validation for this nontranslatable field
              const nonTranslatableFieldSchema = _.pick(contentSchema.fields, key)[key]
              if (nonTranslatableFieldSchema) {
                acc[key] = nonTranslatableFieldSchema // { nonTranslatableField: validationForNonTranslatableField }
              }
            }

            return acc
          },
          {}
        )
      )

      await schema.validate(values, {
        abortEarly: false,
        context: { defaultLocaleCode: defaultLocale.code }
      })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return error.inner.reduce((acc: Record<string, any>, { path, message }) => {
          if (!path) {
            return acc
          }

          if (isLocaleCode(path)) {
            const [code, field] = path.split('.')
            return {
              ...acc,
              [code]: {
                ...acc[code],
                [field]: message
              }
            }
          }
          return {
            ...acc,
            [path]: message
          }
        }, {})
      }

      throw resolveUnknownError(error)
    }
  }
  return validator
}

const mergeFields = (rootFieldsArray: string[], ...otherFieldsArrays: any[]) => {
  return _(rootFieldsArray).concat(_.flatten(otherFieldsArrays)).compact().uniq().value()
}

const ContentContainer = styled.div`
  position: relative;
  overflow: auto;
  padding: var(--spacing-xlarge) var(--spacing-medium) var(--spacing-xxlarge);
  background-color: var(--color-white);
  height: 100%;

  > *:first-child {
    &[data-showbackground='false'] {
      padding-top: 0px;
    }
  }
  ${FormField} + ${FormFieldSection} {
    margin-top: -16px;
  }
`

const TranslationsDropdownContainer = styled.div<{ isShadow: boolean }>`
  padding: 16px 24px;
  box-shadow: ${({ isShadow }) => (isShadow ? '0 2px 2px 0 rgba(0, 0, 0, 0.3)' : 'none')};
  z-index: 10000;
`

const StyledLoadingSpinner = styled(LoadingOverlay)`
  z-index: 10001; // Higher than <TranslationsDropdownContainer />
`

interface TranslationFormApiConfig {
  query: DocumentNode
  mutation: {
    create: DocumentNode
    update: DocumentNode
    delete: DocumentNode
  }
}

interface IMutationValidationArgs {
  contentId?: number
  values: FormikValues
  initialValues: FormikValues
}
type MutationValidationFunction = (
  args: IMutationValidationArgs
) => Promise<DialogOptions | undefined>

interface IAdditionalFields {
  translatable?: string[]
  nontranslatable?: string[]
}

export interface TranslationFormProps {
  contentName: ContentName
  contentId?: number
  apiConfig: TranslationFormApiConfig
  validationSchema?: any
  /**
   * Validation functions are used to handle any content specific validation needed before mutation.
   * Ex: The image form needs to check if it is associated with any Items/Exhibitions where it is the only image associated with that Item/Exhibtion. If so, it will block the delete mutation with a custom dialog.
   */
  validateSave?: MutationValidationFunction
  allowDelete?: boolean
  validateDelete?: MutationValidationFunction
  /**
   * Initial values that are set when initializing a new piece of content (create). Ex: Item -> { type: 'BOTANICAL', published: false }.
   * Only nontranslatable fields are recognized.
   * Typically editable fields that need a value (item.published), or noneditable fields that need a value (item.type).
   * Only set during initialValues, not on additional translations; we do not want to overwrite 'published' when adding a translation.
   */
  defaultValues?: any
  /**
   * Adds the ability to adjust initialValues after they have been generated.
   * Example: An Exhibition's 'displayPeriodType' is a value that depends on other initial values in order to be initialized properly.
   */
  initialValuesParser?: (values: any) => any
  /**
   * Used to edit the translatable values before submission.
   * Can also be used to handle uploading assets prior to calling GQL mutation
   * Examples:
   *    - 'audio.url' is not accepted by the mutation input, it is only necessary for form UI (displaying the audio player).
   *    - Need to upload video via tusd and extract URL prior to creating mutation input
   */
  submitValuesParser?: (
    data: any,
    setLoadingText: (loadingText: string | null) => void,
    setLoadingProgress: (progress: number | null) => void
  ) => Promise<any> | any
  /**
   * Used to explicitly account for fields that are not in the GQL query, but still need to be set via setFieldValue.
   * Example: A fileId for uploading an audio file. This value is not fetched by the GQL query, but is necessary for the GQL mutation input. Another example is uploading an image -- that file will need to be set and included as part of the GQL mutation input.
   * Note:  If you attempt to getFieldValue or setFieldValue with a field that is not part of the GQL query and not explicitly included in additionalFields, this form will throw an error.
   */
  additionalFields?: IAdditionalFields
  children: ReactNode

  // Temporary flag to support moving users to new forms without showing translations
  translationsEnabled?: boolean
  /**
   * Represents the mutation input type specific to the content being represented.
   * Used to help mold the form values into the shape of the actual mutation input prior to submission.
   * Used to help generate the list of accepted translatable and nontranslatable fields in the form.
   */
  inputType: GraphQLInputObjectType

  // Fields to validate and show errors if present on form open.
  initialValidationFields?: string[]

  extraBannerComponent?: ReactNode
}

const TranslationForm = (props: TranslationFormProps) => {
  const {
    contentName,
    contentId,
    apiConfig: queryConfig,
    validationSchema,
    validateSave = () => Promise.resolve(undefined),
    allowDelete = true,
    validateDelete = () => Promise.resolve(undefined),
    defaultValues,
    initialValuesParser = _.identity,
    submitValuesParser,
    additionalFields = { translatable: [], nontranslatable: [] },
    translationsEnabled = true,
    inputType,
    initialValidationFields,
    extraBannerComponent
  } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { setCurrentDialog, closeDialog, currentDialog } = useDialog<FormHeaderDialogType>()

  const isFormCreating = _.isNil(contentId)
  // eslint-disable-next-line docent/require-translation-keys-to-be-literals
  const title = t(`${isFormCreating ? 'Add' : 'Edit'} ${contentName}`)

  const [isLoading, setIsLoading] = useState(false)
  const [loadingText, setLoadingText] = useState<string | null>(null)
  const [loadingProgress, setLoadingProgress] = useState<number | null>(null)
  const [isContentScrolled, setIsContentScrolled] = useState(false)

  const setLoadingStatus = useCallback(
    (loading: boolean | number | undefined, text: string | null = null) => {
      // Setting a percentage
      if (_.isNumber(loading)) {
        setIsLoading(true)
        setLoadingProgress(loading)
        setLoadingText(text)
      }

      // Setting to false
      else if (!loading) {
        setIsLoading(false)
        setLoadingProgress(null)
        setLoadingText(null)
      }

      // Setting to true
      else {
        setIsLoading(true)
        setLoadingProgress(null)
        setLoadingText(text)
      }
    },
    [setIsLoading, setLoadingProgress, setLoadingText]
  )

  const handleScroll: UIEventHandler<HTMLDivElement> = (e) =>
    setIsContentScrolled((e.target as HTMLElement).scrollTop !== 0)

  // form nav
  const closeForm = () => {
    closeDialog()
    navigate('..')
  }

  const onError = (e: Error) => {
    setCurrentDialog(FormHeaderDialogType.SAVE_ERROR, { errorDetails: e })
  }

  const onSubmitComplete = () => {
    dispatch(showChangesSavedToast())
    closeForm()
  }

  // query & mutations
  const { query, mutation } = queryConfig

  const [createOrUpdate, { loading: isMutationCreatingOrUpdating }] = useMutation(
    isFormCreating ? mutation.create : mutation.update,
    { onCompleted: onSubmitComplete, onError }
  )

  const [deleteContent, { loading: isMutationDeleting }] = useMutation(mutation.delete, {
    onCompleted: onSubmitComplete,
    onError
  })

  const isMutationLoading = isMutationCreatingOrUpdating || isMutationDeleting

  const queryOptions = isFormCreating ? undefined : { variables: { id: contentId } }
  const {
    defaultLocale,
    contentData,
    availableLocales,
    loading: isQueryLoading,
    error: queryError
  } = useTranslationFormQuery(query, queryOptions)

  useEffect(() => {
    setIsLoading(isQueryLoading || isMutationLoading)
  }, [isQueryLoading, isMutationLoading])

  const mutationFields = useFieldsFromMutationInput(inputType)
  const queryFields = useFieldsFromQuery(query)

  // establish all known/acceptable fields
  const translatableFields = mergeFields(
    mutationFields.translatable,
    queryFields.translatable,
    additionalFields.translatable
  )
  const nonTranslatableFields = mergeFields(
    mutationFields.nonTranslatable,
    queryFields.nonTranslatable,
    additionalFields.nontranslatable
  )

  const [locales, setLocales] = useLocales(defaultLocale!)
  const [selectedLocale, setSelectedLocale] = useState<GQLLocale>(defaultLocale!)
  const isDefaultLocaleSelected =
    !selectedLocale || !defaultLocale || selectedLocale?.code === defaultLocale?.code

  useEffect(() => {
    // minimum requirement should be defaultLocale, as translations (contentData) could be empty for a new thing
    if (!isQueryLoading && !_.isNil(defaultLocale)) {
      // set the initial selectedLocale, which is always the defaultLocale
      if (_.isNil(selectedLocale)) {
        setSelectedLocale(defaultLocale)
      }

      const contentDataLocales = _.map(contentData?.translations, 'locale')
      // set the list of locales; handle the case where no translations exist and set it to defaultLocale (new thing)
      const localesToSet = _.isEmpty(contentDataLocales) ? [defaultLocale] : contentDataLocales
      setLocales(localesToSet)
    }
  }, [defaultLocale, isQueryLoading, contentData])

  // formik
  const initialValues = useMemo(() => {
    // defaultLocale is required
    if (isQueryLoading || _.isNil(defaultLocale)) {
      return {}
    }

    // We need to set a proper initialValues so Formik can properly handle 'touched' inputs.
    // Without any initialValues, there are no fields (empty values) for Formik to touch -- it is not aware.
    // On Save Formik touches every value automatically. Without any initialValues, there are no actual 'values'...
    // values: { [en-US]: {} }
    // touched: { [en-US]: {} }
    // errors: { [en-US]: { title, url, }}
    // The only reason 'errors' has data, is because we passed a Yup schema, and Formik knows how to deal with that.
    if (isFormCreating) {
      return {
        ..._.zipObject(nonTranslatableFields),
        ..._.pick(defaultValues, nonTranslatableFields),
        [defaultLocale?.code]: _.zipObject(translatableFields)
      }
    }

    const nontranslatable = _.omit(contentData, ['translations'])
    const translations = _.reduce(
      contentData?.translations,
      (acc: Record<string, any>, value) => {
        acc[value.locale.code] = _.omit(value, 'locale')
        return acc
      },
      {}
    )

    return {
      ...nontranslatable,
      ...translations
    }
  }, [contentId, isQueryLoading, defaultLocale, contentData])

  const initialTouched = useMemo(() => {
    if (!initialValues || !contentId || _.isEmpty(initialValidationFields)) {
      return undefined
    }
    const allValidFields = [...nonTranslatableFields, ...translatableFields]
    const unrecognizedFields = _.difference(initialValidationFields, allValidFields)

    if (!_.isEmpty(unrecognizedFields)) {
      throw new Error(`Unknown fields present in initialValidationFields: ${unrecognizedFields}`)
    }

    return (
      _(initialValues)
        .mapValues((value, key) => {
          if (isLocaleCode(key)) {
            // Generate touched fields with shape: { field1: true, field2: true }
            return _(initialValidationFields)
              .keyBy()
              .mapValues(() => true)
              .value()
          }
          return _.includes(initialValidationFields, key)
        })
        // Note: since our data shape can contain values at the top level (not all data are inside the locale code),
        // the value can be a boolean or an object (in the case for data inside a locale)
        .pickBy((value) => !_.isEmpty(value) || value === true)
        .value()
    )
  }, [initialValues, initialValidationFields])

  const getFieldName = (name: string) => {
    const parts = _.split(name, '.')
    const rootName = _.first(parts)
    const isTranslatableField = _.includes(translatableFields, rootName)
    const isNonTranslatableField = _.includes(nonTranslatableFields, rootName)

    if (!isNonTranslatableField && !isTranslatableField) {
      throw new Error(
        `Unknown field name '${name}'.  Ensure this field has been added to the GQL query in the API config, is part of the GQL mutation input type, or added to the additionalFields prop.`
      )
    }

    return isTranslatableField ? `${selectedLocale?.code}.${name}` : name
  }

  const getFieldValue = (formikValues: any) => (name: string) =>
    _.get(formikValues, getFieldName(name))

  const setFieldValue =
    (formikSetFieldValue: FormikProps<any>['setFieldValue']) => (name: string, value: any) =>
      formikSetFieldValue(getFieldName(name), value)

  // locales
  const onAddLocale = (locale: GQLLocale, setFieldValueFunc: FormikProps<any>['setFieldValue']) => {
    setSelectedLocale(locale)
    setLocales([...locales, locale])

    // when adding a previously saved translation back into the form, use the data in the DB
    const translationValue =
      _.find(contentData?.translations, {
        locale: { code: locale.code }
      }) || _.zipObject(translatableFields)

    // update formik values
    setFieldValueFunc(locale.code, _.omit(translationValue, 'locale'))
  }

  // Used to reset the touched status of translations that have been removed
  const resetTouchedForLocale = (
    setFieldTouched: FormikProps<any>['setFieldTouched'],
    locale: GQLLocale
  ) => {
    // we limit this to translatable fields because this reset is specific to translations (not default locale)
    _.forEach(translatableFields, (field) =>
      setFieldTouched(`${locale.code}.${field}`, false, false)
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onRemoveLocale = (
    setFieldTouchedFunc: FormikProps<any>['setFieldTouched'],
    setFieldValueFunc: FormikProps<any>['setFieldValue']
  ) => {
    const indexToBeRemoved = _.findIndex(locales, { code: selectedLocale.code })
    // remove it from locales
    setLocales(_.reject(locales, { code: selectedLocale.code }))

    // remove it from formik values;
    setFieldValueFunc(selectedLocale.code, undefined, true) // boolean is 'shouldValidate'; yes, we want to re-validate to clear errors on removed locale

    // clear the touched status for fields of a removed locale;
    // if you add a locale, cause inline errors, remove the locale, then add locale again, Formik remembers those fields have been touched;
    // you will get inline errors immediately -- no good;
    resetTouchedForLocale(setFieldTouchedFunc, selectedLocale)

    // set selectedLocale to previous in the list
    setSelectedLocale(_.nth(locales, indexToBeRemoved - 1)!)
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onCancel = (initialValues: FormikValues, values: FormikValues) => {
    const { isDefaultDirty, translations } = getDirtyLocaleCodes(
      initialValues,
      values,
      defaultLocale!
    )
    const { editedOrAdded } = translations

    // close form if no edits were made
    if (!isDefaultDirty && _.isEmpty(editedOrAdded)) {
      closeForm()
      return
    }

    setCurrentDialog(FormHeaderDialogType.CANCEL)
  }

  const handleSubmit = async (values: FormikValues) => {
    setIsLoading(true)
    // this values conversion will be removed when we instead initialize formik values shape to match mutation input
    const nontranslatable = _.pick(values, nonTranslatableFields)
    const translations = _(values)
      .omit(nonTranslatableFields)
      // we only want the `localeCode` keys at this point.
      .pickBy((value, key) => isLocaleCode(key))
      .map((value, code) => ({
        localeCode: code,
        ..._.pick(value, translatableFields)
      }))
      .value()

    // these values represent formik values that have been re-arranged to further match shape of mutation input
    const convertedValues = {
      ...nontranslatable,
      translations
    }

    try {
      const parsedInput = submitValuesParser
        ? await submitValuesParser(convertedValues, setLoadingText, setLoadingProgress)
        : convertedValues

      const mappedInput = mapValuesToMutationInput(parsedInput, inputType)
      const input = _.isEmpty(mappedInput.translations)
        ? _.omit(mappedInput, 'translations')
        : mappedInput

      // As per docs, there is no need to await this function supplied by the useMutation hook
      createOrUpdate({ variables: { input, id: contentId } })
    } catch (e) {
      onError(e as ApolloError)
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const onSave = async (values: FormikValues) => {
    const { isDefaultDirty, translations } = getDirtyLocaleCodes(
      initialValues,
      values,
      defaultLocale!
    )
    const { editedOrAdded, removed } = translations
    const isEditedAddedOrRemoved = !_.isEmpty([...editedOrAdded, ...removed])

    // close form if no edits were made
    if (!isDefaultDirty && !isEditedAddedOrRemoved) {
      closeForm()
      return
    }

    setIsLoading(true)
    const options = await validateSave({ contentId, values, initialValues })

    // if changes to default only and no validation options, then no dialog for Save
    if (isDefaultDirty && !isEditedAddedOrRemoved && !options) {
      handleSubmit(values)
      return
    }

    setIsLoading(false)
    setCurrentDialog(FormHeaderDialogType.SAVE, options)
  }

  const onDelete = async (values: FormikValues) => {
    setIsLoading(true)
    const options = await validateDelete({ contentId, values, initialValues })
    setIsLoading(false)
    setCurrentDialog(FormHeaderDialogType.DELETE, options)
  }

  return (
    <Formik
      initialValues={initialValuesParser(initialValues)}
      enableReinitialize={true}
      onSubmit={onSave}
      validate={schemaValidator(validationSchema, nonTranslatableFields, defaultLocale!)}
      initialTouched={initialTouched}
      validateOnMount={!_.isEmpty(initialTouched)}
    >
      {({
        values,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        initialValues,
        setFieldValue: formikSetFieldValue,
        setFieldTouched,
        handleSubmit: formikSubmit,
        touched,
        errors,
        isSubmitting,
        isValidating
      }: FormikProps<any>) => {
        return (
          <TranslationFormContext.Provider
            // TODO: we should memoize or break this up, rather than disabling this rule
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
              contentId,
              values,
              initialValues,
              locales,
              availableLocales,
              isDefaultLocaleSelected,
              getFieldName,
              setLoadingStatus,
              getFieldValue: getFieldValue(values),
              setFieldValue: setFieldValue(formikSetFieldValue),
              onError,
              contentName,
              defaultLocale,
              selectedLocale
            }}
          >
            {/* TODO: need to cleanup FormHeaderDialogs to have DiscardChangesDialog shared between TranslationForm and EnhancedStandardForm */}
            <FormHeaderDialogs
              dialog={currentDialog}
              closeForm={closeForm}
              closeDialog={closeDialog}
              delete={() => deleteContent({ variables: { id: contentId } })}
              save={() => handleSubmit(values)}
            />
            <PopupWidget zIndex={100}>
              {isLoading && (
                <StyledLoadingSpinner loadingText={loadingText} progress={loadingProgress} />
              )}
              {isValidating && <div style={{ display: 'none' }} data-testid="formik-validating" />}
              <FormHeader
                title={title}
                onDelete={isFormCreating ? undefined : () => onDelete(values)}
                allowDelete={allowDelete}
                onCancel={() => onCancel(initialValues, values)}
                onSave={formikSubmit}
                enableSave={true}
              />

              {extraBannerComponent}
              <ErrorBanner
                errors={errors}
                showInitialErrors={!_.isEmpty(initialTouched)}
                isSubmitting={isSubmitting}
                selectedLocale={selectedLocale}
                defaultLocale={defaultLocale!}
                locales={locales}
                translationsEnabled={translationsEnabled}
              />

              {values.externalId && (
                <Banner type="warning" headingText={t('This content was created via bulk upload.')}>
                  <BannerItem>
                    {t('Manual edits will be overwritten by your next bulk upload.')}
                  </BannerItem>
                </Banner>
              )}

              {translationsEnabled && (
                <TranslationsDropdownContainer isShadow={isContentScrolled}>
                  <TranslationsDropdown
                    defaultLocale={defaultLocale!}
                    locales={locales}
                    selectedLocale={selectedLocale}
                    availableLocales={availableLocales}
                    onSelect={(locale) => setSelectedLocale(locale)}
                    onAdd={(locale) => onAddLocale(locale, formikSetFieldValue)}
                    onRemove={() => onRemoveLocale(setFieldTouched, formikSetFieldValue)}
                    errors={errors}
                    touched={touched}
                  />
                </TranslationsDropdownContainer>
              )}

              <ContentContainer onScroll={handleScroll}>
                {queryError ? <GQLErrorRenderer error={queryError} /> : props.children}
              </ContentContainer>
            </PopupWidget>
          </TranslationFormContext.Provider>
        )
      }}
    </Formik>
  )
}

export default TranslationForm
