import _ from 'lodash'
import styled from 'styled-components'
import SelectBox, {
  ISelectBoxProps,
  ISelectBoxOptions
} from 'client/components/Form/SelectBox/SelectBox'
import i18n, { t } from 'client/i18n'
import { setHours, setMinutes } from 'date-fns'

function constructDate(hours: number, minutes: number) {
  let date = new Date()
  date = setHours(date, hours)
  date = setMinutes(date, minutes)
  return date
}

const createLabel = (hours: number, minutes: number): string => {
  const date = constructDate(hours, minutes)
  return new Intl.DateTimeFormat(i18n.language, {
    hour: 'numeric',
    minute: 'numeric'
  }).format(date)
}

const createSelectOptions = (): ISelectBoxOptions[] => {
  const hours: ISelectBoxOptions[] = []
  for (let hour = 0; hour < 24; hour += 1) {
    for (let minIncrement = 0; minIncrement < 12; minIncrement += 1) {
      const minute = minIncrement * 5
      const label = createLabel(hour, minute)
      const value = `${hour < 10 ? 0 : ''}${hour}:${minute < 10 ? 0 : ''}${minute}`
      hours.push({ label, value })
    }
  }
  return hours
}

export const TWENTY_FOUR_HOURS = t('24 Hours')
const HOURS_OPTIONS = createSelectOptions()
const HOURS_OPTIONS_WITH_24 = [
  { label: TWENTY_FOUR_HOURS, value: TWENTY_FOUR_HOURS },
  ...HOURS_OPTIONS
]

export const getOptionByValue = (
  value: string,
  options: ISelectBoxOptions[] = HOURS_OPTIONS_WITH_24
) => _.find(options, { value })

const selectFilterOption: ISelectBoxProps['filterOption'] = (option, input) => {
  const label = input?.includes(':') ? option?.label : option?.label.replace(':', '')
  return label?.startsWith(input)
}

const SelectBoxContainer = styled.div`
  width: 150px;
  height: 40px;
`

interface IHoursInputSelectProps extends Omit<ISelectBoxProps, 'options'> {
  is24HourDisabled?: boolean
  hasError?: boolean
}

export const HoursInputSelect = (props: IHoursInputSelectProps) => {
  return (
    <SelectBoxContainer>
      <SelectBox
        isSearchable={true}
        options={props.is24HourDisabled ? HOURS_OPTIONS : HOURS_OPTIONS_WITH_24}
        filterOption={selectFilterOption}
        {...props}
      />
    </SelectBoxContainer>
  )
}
